/*Colors*/
$colors: (
  'gray': rgb(138, 151, 152),
  'dark-blue': rgb(0, 19, 31),
  'light-gray': rgb(240, 250, 251),
  'teal': rgb(0, 173, 187),
  'soft-gray': rgb(198, 214, 228),
  'dark-gray': rgb(116, 131, 145),
  'deep-gray': rgb(97, 111, 116),
  'primary': rgb(0, 20, 27),
  'light-blue': rgb(215, 232, 247),
  'white': rgb(255, 255, 255),
  'medium-gray': rgb(186, 195, 202),
  'yellow': rgb(249, 227, 25),
  'light-yellow': rgb(253, 243, 54),
  'coal-gray': rgb(44, 49, 49),
  'card-gray': rgb(214, 222, 229),
  'blue': rgb(41, 63, 255),
  'naka-light-blue': rgb(236, 242, 254),
  'shadow-blue': rgb(207, 221, 253),
  'btn-gray': rgb(91, 100, 101),
  'gray-light-divider': rgb(246, 249, 251),
  'disabled-gray': rgb(250, 251, 253),
  'gray-100': rgb(248, 248, 248)
);

/*Font sizes*/
$textSizes: (
  '8': 0.5rem,
  '9': 0.563rem,
  '10': 0.625rem,
  '11': 0.688rem,
  '12': 0.75rem,
  '13': 0.813rem,
  '14': 0.875rem,
  '15': 0.938rem,
  'default': 1rem,
  '18': 1.125rem,
  '20': 1.25rem,
  '22': 1.375rem,
  '24': 1.5rem,
  '25': 1.563rem,
  '26': 1.625rem,
  '29': 1.813rem,
  '32': 2rem,
  '40': 2.5rem,
  '44': 2.75rem,
  '45': 2.813rem,
  '46': 2.875rem,
  '48': 3rem
);

/*Other rules*/
$primaryFontFamily: var(--font-family-regular), sans-serif;
$secondaryFontFamily: var(--font-family-medium), sans-serif;
$customRadius: var(--button-primary-border-radius);
$defaultPadding: 1rem;
$defaultGridGap: 1rem;
$defaultRadius: 0.25rem;
$nakaRadius: 100vh;
