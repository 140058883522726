@use 'rules' as rules;

@mixin grid-row-layout($rowFrame, $gap) {
  display: grid;
  grid-template-rows: $rowFrame;
  @if $gap != unset {
    grid-gap: $gap;
  }
}

@mixin grid-column-layout($columnFrame, $gap) {
  display: grid;
  grid-template-columns: $columnFrame;
  @if $gap != unset {
    grid-gap: $gap;
  }
}

@mixin flex-layout($flex, $justify, $itemsAlign, $gap) {
  display: $flex;
  @if $justify != unset {
    justify-content: $justify;
  }
  @if $itemsAlign != unset {
    align-items: $itemsAlign;
  }
  @if $gap != unset {
    gap: $gap;
  }
}

@mixin flex-direction($direction) {
  display: flex;
  flex-direction: $direction;
}

@mixin font-family($family, $size) {
  font-family: $family, sans-serif;
  font-size: $size;
}

@mixin remove-native-focus {
  &:focus,
  &:active,
  &:focus-visible {
    box-shadow: none;
    border: 0;
    outline: 0;
  }
}

@mixin remove-list-style {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin font-style($size, $color) {
  font-size: map-get(rules.$textSizes, $size);
  line-height: 1.4;
  @if $color != unset {
    color: map-get(rules.$colors, $color);
  }
}

@mixin grid-gap($gap) {
  display: grid;
  @if ($gap != unset) {
    grid-gap: $gap;
  }
}

@mixin thin-scrollbar($thumbColor, $thumbHoverColor, $barColor, $width, $radius) {
  scrollbar-width: thin;
  scrollbar-color: $thumbColor $barColor;
  &::-webkit-scrollbar {
    width: $width;
    transition: width 0.25s ease-in-out;
    &:hover {
      width: calc(#{$width} * 1.25);
    }
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $thumbColor;
    transition: all 0.25s ease-in-out;
    &:hover {
      background: $thumbHoverColor;
    }
  }
  &::-webkit-scrollbar-track {
    background: $barColor;
  }
}
