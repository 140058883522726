@font-face {
  font-family: 'Maax-Regular';
  src: url('maax_regular/maax_regular.eot');
  src: url('maax_regular/maax_regular.eot?#iefix') format('embedded-opentype'), url('maax_regular/maax_regular.woff2') format('woff2'), url('maax_regular/maax_regular.woff') format('woff'), url('maax_regular/maax_regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Maax-Medium';
  src: url('maax_medium/maax_medium.eot');
  src: url('maax_medium/maax_medium.eot?#iefix') format('embedded-opentype'), url('maax_medium/maax_medium.woff2') format('woff2'), url('maax_medium/maax_medium.woff') format('woff'), url('maax_medium/maax_medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Maax-Medium-Italic';
  src: url('maax_medium_italic/maax_medium_italic.eot');
  src: url('maax_medium_italic/maax_medium_italic.eot?#iefix') format('embedded-opentype'), url('maax_medium_italic/maax_medium_italic.woff2') format('woff2'), url('maax_medium_italic/maax_medium_italic.woff') format('woff'), url('maax_medium_italic/maax_medium_italic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
    font-family: Isola;
    src: url('Isola/IsolaWeb-Light.woff');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: Isola;
    src: url('Isola/IsolaWeb-Regular.woff');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: Isola;
    src: url('Isola/IsolaWeb-SemiBold.woff');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: Isola;
    src: url('Isola/IsolaWeb-Bold.woff');
    font-weight: 700;
}

@font-face {
    font-family: Isola;
    src: url('Isola/IsolaWeb-Black.woff');
    font-weight: 900;
    font-display: swap;
}
