@use 'assets/css/framework' as framework;
@import 'assets/fonts/fonts.css';

/////////////////////////////// GENERAL ///////////////////////////////
html {
  font-family: var(--font-family-regular), sans-serif;
  overflow: hidden !important;
}

body {
  font-family: var(--font-family-regular), sans-serif;
  overflow: hidden !important;
  line-height: 1;
  &.gradient {
    background: url('assets/shadow-desktop@2x.png') top center repeat-y,
      linear-gradient(
        90deg,
        rgba(247, 248, 248, 1) 0%,
        rgba(247, 248, 248, 1) 50%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );
  }

  &.bg-white {
    background: #fff;
  }

  &.bg-grey {
    background: #f7f8f8;
  }

  &.default {
    overflow: auto !important;

    .main-container {
      padding-left: 20px;
      padding-right: 20px;
    }

    footer {
      padding: 24px 0 !important;
      position: relative;
      left: -5px;
      .container {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
  }

  &.mobile {
    footer {
      background: #f7f8f8;
      padding: 14px 24px !important;
      margin-top: 20px;

      .container {
        padding: 0 !important;
      }
    }
  }

  &.modal-open {
    .modal-backdrop {
      &.show {
        background: rgb(0, 20, 27);
        opacity: 0.2;
      }
    }
  }
}

img {
  font-size: 0;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.p-20 {
  padding: 20px !important;
}

.text-black {
  color: #00131f;
}

/////////////////////////////// MODAL ///////////////////////////////
.modal-dialog {
  max-width: 345px;
}

.modal-body {
  padding: 24px;
  overflow: hidden;
}

.modal-content {
  max-width: 345px;
  margin: 0;
  border-radius: 8px;
  border: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 173, 187, 0.07), 0 10px 20px 0 rgba(0, 173, 187, 0.07);

  h1 {
    font-size: 18px;
    font-family: var(--font-family-medium), sans-serif;
    font-weight: 500;
    color: #00131f;
    margin: 0;
  }
}

.modal-footer {
  padding: 0 24px 24px;
  border-top: 0;
}

/////////////////////////////// BUTTONS ///////////////////////////////
.btn-link-primary {
  padding: 0;
  color: var(--link-color);
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-primary {
  background: var(--button-primary-background-color);
  color: var(--button-primary-color);
  border-radius: var(--button-primary-border-radius);
  font-size: 14px;
  padding: 10px;
  margin: 0;
  box-shadow: none;

  &:hover {
    background: var(--button-primary-background-color-hover);
    color: var(--button-primary-color-hover);
    box-shadow: none;
  }

  &:focus {
    background: var(--button-primary-background-color-focus);
    color: var(--button-primary-color-focus);
    box-shadow: none;
  }

  &:active {
    background: var(--button-primary-background-color-active);
    color: var(--button-primary-color-active);

    &:focus {
      box-shadow: none;
    }
  }
}

.btn-secondary {
  background: var(--button-secondary-background-color);
  border: var(--button-secondary-border);
  color: var(--button-secondary-color);
  border-radius: var(--button-secondary-border-radius);
  font-size: 14px;
  padding: 10px;
  margin: 0;
  box-shadow: 0px 10px 20px 0px rgba(0, 173, 187, 0.07);

  &:hover {
    background: var(--button-secondary-background-color-hover);
    border: var(--button-secondary-border-hover);
    color: var(--button-secondary-color-hover);
  }

  &:focus {
    background: var(--button-secondary-background-color-focus);
    border: var(--button-secondary-border-focus);
    color: var(--button-secondary-color-focus);
    box-shadow: none;
  }

  &:active {
    background: var(--button-secondary-background-color-active);
    border: var(--button-secondary-border-active);
    color: var(--button-secondary-color-active);

    &:focus {
      box-shadow: none;
    }
  }
}

.btn-yellow {
  background: var(--button-yellow-background-color);
  border: 1px solid var(--button-yellow-border);
  color: var(--button-yellow-color);
  border-radius: var(--button-yellow-border-radius);
  font-family: var(--font-family-medium), sans-serif;
  font-size: map-get(framework.$textSizes, '12');
  font-weight: 700;
  padding: 10px;
  margin: 0;
  box-shadow: 0 10px 20px 0 rgba(0, 173, 187, 0.07);

  &:hover {
    background: var(--button-yellow-background-color-hover);
    border-color: var(--button-yellow-border-hover);
    color: var(--button-yellow-color-hover);
  }

  &:focus {
    background: var(--button-yellow-background-color-focus);
    border-color: var(--button-yellow-border-focus);
    color: var(--button-yellow-color-focus);
    box-shadow: 0px 10px 20px 0px rgba(0, 173, 187, 0.07);
  }

  &:active {
    background: var(--button-yellow-background-color-active);
    border-color: var(--button-yellow-border-active);
    color: var(--button-yellow-color-active);
    box-shadow: 0px 10px 20px 0px rgba(0, 173, 187, 0.07);

    &:focus {
      box-shadow: 0px 10px 20px 0px rgba(0, 173, 187, 0.07);
    }
  }
}

.btn.btn-block {
  display: block;
  width: 100%;
}

/////////////////////////////// FORM ///////////////////////////////
.form-group {
  label {
    font-size: 12px;
    color: #7f8c8d;
    margin-bottom: 4px;
  }

  .form-control {
    background: #ffffff;
    color: #00131f;
    border: 1px solid #edeeee;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    border-radius: var(--form-border-radius);

    &:focus {
      box-shadow: none;
    }

    &.form-control-readonly {
      cursor: default;
    }

    &.form-control-disabled {
      background: transparent;
      border: 1px solid map-get(framework.$colors, 'card-gray');
      border-left: 0;
      cursor: default;
    }
  }

  &.has-error {
    .form-control {
      border-color: #eb545a;
      color: #eb545a;
    }

    .form-check-input {
      border-color: #eb545a;
    }
  }

  &.form-group-error {
    .form-control {
      border-color: #eb545a;
    }
  }

  .form-error-label {
    display: block;
    font-size: 11px;
    padding: 0 13px;
    margin-top: 3px;
    color: #eb545a;
  }
}

.form-group-label {
  font-family: var(--font-family-medium), sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #00131f;
  margin-top: 30px;
}

.input-group-append .btn {
  font-family: var(--font-family-medium), sans-serif;
  font-weight: 500;
  background: #c6d6e4;
  color: #00131f;
  border: 1px solid #edeeee;
  border-radius: 0 4px 4px 0;
  z-index: 4;
  text-transform: uppercase;
  font-size: 10px;
  padding: 11px 9px 10px 8px;

  &::before {
    position: absolute;
    content: ' ';
    width: 16px;
    height: 16px;
    background: url('assets/icons/copy-icon.svg') no-repeat;
    background-size: 16px 16px;
    margin-top: -1px;
  }

  &:hover {
    background: #9eb0c0;
    box-shadow: inset 2px 2px 4px #7991a7;
  }

  &:active {
    &:before {
      background: url('assets/icons/copy-icon-white.svg') no-repeat;
    }
  }

  span {
    margin-left: 25px;
  }
}

.input-group-prepend {
  border: 1px solid #d7e8f7;
  border-right: 0;
  border-radius: 4px 0 0 4px;
  padding: 6px 0 6px 10px;
  line-height: 100%;

  img {
    width: 24px;
    height: 24px;
  }
}

.select2-container {
  margin-top: -10px !important;
}

.select2-selection {
  border: 1px solid #edeeee !important;
  height: 40px !important;
  font-size: 14px !important;
  line-height: 100% !important;
  margin-top: 11px !important;
}

.select2-selection__rendered {
  margin-top: 5px !important;

  span {
    margin-left: 4px;
  }

  .select2-selection__placeholder {
    color: #7f8c8d !important;
    margin-left: -4px;
  }
}

.select2-selection__arrow {
  margin-right: 10px;

  &:before {
    content: ' ';
    width: 14px !important;
    height: 8px !important;
    background: url('assets/icons/arrow-down-big-icon.svg') no-repeat;
    background-size: 14px 8px;
    border: 0 !important;
  }
}

.select2-container--open {
  .select2-selection__arrow {
    &:before {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

.select2-results {
  padding: 10px 0 !important;
}

.select2-results__option {
  font-size: 14px;
  padding: 6px 13px !important;
  background: #fff !important;
}

.select2-results__option--highlighted {
  padding: 6px 13px !important;
  background: #fff !important;
  color: #00131f !important;
  font-family: var(--font-family-medium), sans-serif;
  font-weight: 500;
}

.select2-dropdown.select2-dropdown--below {
  border-color: #edeeee !important;
}

.select2-search--dropdown {
  padding: 10px 10px 0 10px !important;
}

.select2-search__field,
.select2-search__field:focus {
  padding: 6px 12px !important;
  border: 1px solid #edeeee !important;
  border-radius: 6px !important;
  margin-top: 6px !important;
  font-size: 14px !important;
}

.form-check {
  margin-top: 16px;
  margin-bottom: 0;

  .form-check-input {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 12px;
    border: 1px solid #edeeee;
    margin-top: -3px;

    &:checked {
      background: var(--check-bg) var(--check-icon) center center no-repeat;
      border: 1px solid #edeeee;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .form-check-label {
    margin-bottom: 0;
    color: #00131f;
    display: block;

    a {
      color: var(--default-color);
    }
  }
}

/////////////////////////////// TABLE ///////////////////////////////
.table > thead {
  font-weight: bold;
}

/////////////////////////////// LAYOUT ///////////////////////////////
.main-wrapper {
  max-height: calc(100vh - 72px) !important;
}

main {
  min-height: 100%;
  .container {
    min-height: 100%;
    .row {
      min-height: 100% !important;
    }
  }
}

.container {
  max-width: 820px;

  p {
    margin: 0;
  }
}

.container-col {
  padding: calc(64px + #{framework.$defaultPadding} * 5) 0 0;
  &.charge-details-col-wrapper {
    display: grid;
    grid-template-rows: 1fr auto;
    min-height: 100vh;
  }

  .col-wrapper {
    overflow: auto;
    height: 100%;
  }

  .charge-wrapper {
    max-height: calc(100vh - 170px - 72px);
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: #dce1e1;
}

.divider-light {
  background: map-get(framework.$colors, 'gray-light-divider');
}

/////////////////////////////// PAGES ///////////////////////////////
.page-container {
  max-width: 600px;
  margin: auto;
  padding-top: 64px;
  padding-bottom: 60px;

  .page-title {
    font-family: var(--font-family-medium), sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: #00131f;
    margin-bottom: 5px;
  }

  .page-date {
    font-size: 14px;
    line-height: 20px;
  }

  p {
    padding: 0;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
  }

  .accordion-item {
    border: 0;
    border-radius: 0;

    &:last-child {
      border-bottom: 0;
    }

    &:last-of-type {
      .accordion-button.collapsed {
        border-radius: 0;
      }
    }
  }

  .accordion-header {
    .accordion-button {
      padding: 15px 0;
      font-family: var(--font-family-medium), sans-serif;
      font-weight: 500;
      font-size: 16px;
      color: #00131f;
      border-radius: 0;
      border-bottom: 1px solid #dce1e1;
    }

    &:focus,
    &:active,
    .active,
    &:not(.collapsed) {
      box-shadow: none;
      outline: none;
      background: none;
    }
  }

  .accordion-body {
    padding: 15px 0;
    font-size: 14px;
    line-height: 24px;
    color: #00131f;
  }
}

.page-container .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/////////////////////////////// ICONS ///////////////////////////////
.icon-reload {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: var(--icon-reload) no-repeat center center;
  background-size: 14px 14px;
  margin-top: 2px;
  margin-bottom: -2px;
  margin-right: 8px;
}

////////////////////////////// PAYMENT //////////////////////////////
.payment-title {
  font-family: var(--font-family-medium), sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #00131f;
  margin-bottom: calc(#{framework.$defaultPadding} * 1.5);
}

.payment-wrapper {
  width: 100%;
  height: auto;
  border-radius: 4px;
  background: map-get(framework.$colors, 'gray-100');

  .payment-status-btn {
    margin-top: calc(#{framework.$defaultPadding} * 1.5);
    position: relative;
    @include framework.font-style('12', 'coal-gray');
    font-weight: 700;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-reload {
      left: framework.$defaultPadding;
      position: absolute;
      margin: unset;
    }
  }
  .form-check {
    .form-check-label {
      padding-top: 3px;
    }
  }
}

.payment-details-title {
  @include framework.font-style('18', 'dark-blue');
  font-weight: 700;
  text-align: center;
  margin-bottom: calc(#{framework.$defaultPadding} * 1.5);
}

.payment-description-wrapper {
  margin-top: 2px;
  text-align: center;
  padding: 14px;

  p {
    display: block;
    @include framework.font-style('10', 'dark-blue');
  }

  a {
    display: inline-block;
    font-size: map-get(framework.$textSizes, '10');
    padding: 6px 10px;
  }
}

.payment-details-description {
  @include framework.font-style('14', 'gray');
  text-align: center;
}

.payment-details-redirect-info {
  font-size: 14px;
  color: #8a9798;
  text-align: center;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  body {
    background: #fff;
    overflow: auto !important;
    &.default {
      .main-container {
        padding: 0 calc(#{framework.$defaultPadding} / 1.25);
      }
    }
  }

  .modal-dialog {
    margin: 0.5rem auto;
  }

  header {
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .container {
    max-width: 100%;
  }

  .row-mobile {
    .charge-col-wrapper-mobile {
      // height: calc(100vh - 111px);
      // overflow: auto;
      padding: 0 !important;
      // margin-top: 64px;

      .charge-container {
        position: relative;
        background: #fff;
        padding: calc(#{framework.$defaultPadding} * 1.5) framework.$defaultPadding framework.$defaultPadding;
        border-radius: 8px 8px 0 0;
        box-shadow: 0px -6px 4px 0px rgba(map-get(framework.$colors, 'light-blue'), 0.1),
          0px -20px 14px 0px rgba(map-get(framework.$colors, 'light-blue'), 0.3);
        z-index: 1;

        .previous-step-wrapper {
          position: absolute;
          margin: 0;
          left: 0;

          .previous-step-icon {
            margin-left: calc(#{framework.$defaultPadding} * 1.5);
            padding: 10px 0;
          }

          .previous-step-hover-text {
            display: none !important;
          }
        }
      }

      .charge-details-container {
        background: #f7f8f8;
        padding: 86px 20px calc(#{framework.$defaultPadding} * 2) 20px;
      }
    }

    .alert-wrapper {
      width: calc(100% - 40px) !important;
      bottom: 16px !important;
    }
  }
}
